import React, { useState, useEffect, useRef } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";
import { 
  getReimburse,
  getTotalReimburse,
  getDelegasiByIdReimburse,
  getDanaSeleksiByIdReimburse,
  getDanaLombaByIdReimburse,
  deleteReimburse
} from "../../../../../../../Services/API/reimburse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getImgFromUrl } from "../../../../../../../Services/Image";
import { rupiahCurrencyFormat } from "../../../../../../../Services/Format/currency";
import swal from "sweetalert";

function Reimburse() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);

  // Reimburse --------------------------------------------------------

  const generateProposalReimburse = async (event, target) => {
    event.preventDefault();

    const doc = new jsPDF("p", "pt", "a4");
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    let yCurrent = 180;

    const [
      reimburseHeader, 
      ttdKetuaBEM, 
      reimburseDelegasi, 
      reimburseDanaSeleksi, 
      reimburseDanaLomba
    ] = await Promise.all([
      getImgFromUrl(
        require("../../../../../../../Assets/Miscellaneous/header-reimburse.jpg")
      ),
      getImgFromUrl(
        require("../../../../../../../Assets/Miscellaneous/ttd-ketua-bem.jpeg")
      ),
      getDelegasiByIdReimburse(
        target.id_reimburse
      ),
      getDanaSeleksiByIdReimburse(
        target.id_reimburse
      ),
      getDanaLombaByIdReimburse(
        target.id_reimburse
      )
    ]);

    const namaKetuaBEM = "Nadhif Wiratara Wibowo";

    const validateContentHeight = (contentHeight) => {
      if(contentHeight > pageHeight) {
        doc.addPage();
        yCurrent = 0;
    
        doc.addImage(reimburseHeader, "jpg", 17, 20, pageWidth - 40, 80);
    
        doc.line(30, 110, pageWidth - 30, 110);

        yCurrent = 150;
      }
    };

    doc.addImage(reimburseHeader, "jpg", 17, 20, pageWidth - 40, 80);

    doc.line(30, 110, pageWidth - 30, 110);

    doc.setFontSize(15);
    doc.text(
      "Proposal Pengajuan Pengembalian Dana Delegasi IKM FKUI",
      pageWidth / 2,
      140,
      null,
      null,
      "center"
    );

    doc.setFontSize(12);

    doc.text(
      "A. INFORMASI DELEGASI",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent = 210;

    let mahasiswaNumber = 1;

    if (reimburseDelegasi.data && reimburseDelegasi.data.length > 0) {
      reimburseDelegasi.data.forEach((item) => {
        doc.text(
          mahasiswaNumber.toString() + ".",
          30,
          yCurrent,
          null,
          null,
          "left"
        );

        doc.text(
          `Nama           : ${item.nama_lengkap}`,
          65,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 20;
        
        doc.text(
          `Angkatan     : ${item.angkatan}`,
          65,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 20;

        doc.text(
          `NPM            : ${item.npm}`,
          65,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 30;

        validateContentHeight(yCurrent + 44);

        mahasiswaNumber++;
      });
    } else {
      doc.text("Tidak terdefinisi", 30, yCurrent, null, null, "left");

      yCurrent += 20;
    }

    yCurrent += 14;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "B. INFORMASI PERLOMBAAN",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nama Lomba        : ${target.nama_lomba ? target.nama_lomba : "Tidak tedefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Cabang                 : ${target.cabang_lomba ? target.cabang_lomba : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Penyelenggara     : ${target.penyelenggara_lomba ? target.penyelenggara_lomba : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "Deskripsi              :",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    if(target.deskripsi_lomba.length === 0) {
      doc.text(
        "Tidak terdefinisi",
        135,
        yCurrent,
        null,
        null,
        "left"
      );
    }

    let splitDeskripsiLomba = doc.splitTextToSize(target.deskripsi_lomba.replace(/\n/g, " "), 430);

    doc.text(135, yCurrent, splitDeskripsiLomba);

    yCurrent += (19 * splitDeskripsiLomba.length);

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Lokasi                   : ${target.lokasi_lomba ? target.lokasi_lomba : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    let temp_awalPelaksanaanLomba = target.awal_pelaksanaan_lomba;
    let temp_akhirPelaksanaanLomba = target.akhir_pelaksanaan_lomba;

    doc.text(
      `Waktu                   : ${temp_awalPelaksanaanLomba && temp_akhirPelaksanaanLomba ? temp_awalPelaksanaanLomba === temp_akhirPelaksanaanLomba ? temp_awalPelaksanaanLomba : (temp_awalPelaksanaanLomba + " hingga " + temp_akhirPelaksanaanLomba) : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "Tautan Resmi       :",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    if(target.tautan_resmi_lomba) {
      doc.textWithLink(
        target.tautan_resmi_lomba,
        134,
        yCurrent,
        { url: target.tautan_resmi_lomba }
      );
    } else {
      doc.text(
        "Tidak terdefinisi",
        134,
        yCurrent,
        null,
        null,
        "left"
      );
    }

    yCurrent += 44;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "C. INFORMASI SELEKSI",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Penanggung Jawab       : ${target.penanggung_jawab_seleksi ? target.penanggung_jawab_seleksi : "Tidak ada penanggung jawab"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(reimburseDanaSeleksi.data.length > 0 ? 21 * (reimburseDanaSeleksi.data.length + 3) + yCurrent + 54 : 63 + yCurrent + 54);

    doc.text(
      "Rincian Dana Seleksi     :",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 10;

    validateContentHeight(yCurrent + 44);

    const headTableDanaSeleksi = [
      [
        "Jenis Pengeluaran",
        "Total Dana"
      ]
    ];

    const bodyTableDanaSeleksi = 
      reimburseDanaSeleksi.data.length > 0 ? reimburseDanaSeleksi.data.map(item => {
        return [
          item.jenis_pengeluaran,
          rupiahCurrencyFormat(item.total_dana.toString())
        ]
      }).concat([
        [
          "",
          rupiahCurrencyFormat(
            reimburseDanaSeleksi.data.map(item => +item.total_dana).reduce(
              (acc, cur) => acc + cur, 0
            ).toString()
          )
        ]
      ]) : [
      ["Tidak Butuh Dana", "Rp 0,00"]
    ];

    doc.autoTable({
      theme: "grid",
      head: headTableDanaSeleksi,
      headStyles: { fillColor: [130, 130, 130] },
      bodyStyles: { fillColor: [250, 250, 250] },
      body: bodyTableDanaSeleksi,
      startY: yCurrent,
      startX: 0
    });

    yCurrent += reimburseDanaSeleksi.data.length > 0 ? 21 * (reimburseDanaSeleksi.data.length + 3) : 63;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Fasilitas Seleksi             : ${target.fasilitas_seleksi ? target.fasilitas_seleksi : "Tidak ada fasilitas"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 44;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "D. INFORMASI PEMBIMBING",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nama                             : ${!target.nama_pembimbing ? "Tidak ada pembimbing" : target.nama_pembimbing}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Institusi                          : ${!target.nama_pembimbing && !target.institusi_pembimbing && !target.kontak_pembimbing ? "Tidak ada pembimbing" : target.institusi_pembimbing ? target.institusi_pembimbing : "Tidak ada institusi pembimbing"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Kontak                           : ${!target.nama_pembimbing && !target.institusi_pembimbing && !target.kontak_pembimbing ? "Tidak ada pembimbing" : target.kontak_pembimbing ? target.kontak_pembimbing : "Tidak ada kontak pembimbing"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 44;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "E. INFORMASI DANA",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(reimburseDanaLomba.data.length > 0 ? 21 * (reimburseDanaLomba.data.length + 3) + yCurrent + 54 : 63 + yCurrent + 54);

    doc.text(
      "Rincian Dana Lomba      :",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 10;

    validateContentHeight(yCurrent + 44);

    const headTableDanaLomba = [
      [
        "Jenis Pengeluaran",
        "Total Dana"
      ]
    ];

    const bodyTableDanaLomba = 
      reimburseDanaLomba.data.length > 0 ? reimburseDanaLomba.data.map(item => {
        return [
          item.jenis_pengeluaran,
          rupiahCurrencyFormat(item.total_dana.toString())
        ]
      }).concat([
        [
          "",
          rupiahCurrencyFormat(
            reimburseDanaLomba.data.map(item => +item.total_dana).reduce(
              (acc, cur) => acc + cur, 0
            ).toString()
          )
        ]
      ]) : [
      ["Tidak Butuh Dana", "Rp 0,00"]
    ];

    doc.autoTable({
      theme: "grid",
      head: headTableDanaLomba,
      headStyles: { fillColor: [130, 130, 130] },
      bodyStyles: { fillColor: [250, 250, 250] },
      body: bodyTableDanaLomba,
      startY: yCurrent,
      startX: 0
    });

    yCurrent += reimburseDanaLomba.data.length > 0 ? 21 * (reimburseDanaLomba.data.length + 3) : 63;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Total Dana (Seleksi + Lomba)   : ${
        rupiahCurrencyFormat(
          (reimburseDanaSeleksi.data.map(item => +item.total_dana).reduce(
            (acc, cur) => acc + cur, 0
          ) + reimburseDanaLomba.data.map(item => +item.total_dana).reduce(
            (acc, cur) => acc + cur, 0
          )).toString()
        )
      }`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Bank Tujuan                               : ${target.bank_tujuan ? target.bank_tujuan : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nomor Rekening                        : ${target.no_rekening_tujuan ? target.no_rekening_tujuan : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nama Pemilik Rekening             : ${target.nama_pemilik_rekening_tujuan ? target.nama_pemilik_rekening_tujuan : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 44;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "F. PENANGGUNG JAWAB DELEGASI",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nama                      : ${target.nama_penanggung_jawab_delegasi ? target.nama_penanggung_jawab_delegasi : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Angkatan                 : ${target.angkatan_penanggung_jawab_delegasi ? target.angkatan_penanggung_jawab_delegasi : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `NPM                        : ${target.npm_penanggung_jawab_delegasi ? target.npm_penanggung_jawab_delegasi : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nomor HP               : ${target.no_hp_penanggung_jawab_delegasi ? target.no_hp_penanggung_jawab_delegasi : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Email                       : ${target.email_penanggung_jawab_delegasi ? target.email_penanggung_jawab_delegasi : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 40;

    validateContentHeight(yCurrent + 44);

    let splitPernyataanPenanggungJawab = doc.splitTextToSize("Saya selaku penganggung jawab delegasi menyatakan bahwa data yang saya berikan pada proposal delegasi ini benar. Saya juga menyatakan bahwa nomor rekening yang tertera sesuai dengan nama pemilik rekening dan merupakan rekening pribadi saya. Saya mengetahui bahwa permintaan pengembalian dana delegasi ini dapat ditolak apabila data yang saya isikan tidak sesuai.".replace(/\n/g, " "), 540);

    doc.text(30, yCurrent, splitPernyataanPenanggungJawab);

    yCurrent += (20 * splitPernyataanPenanggungJawab.length);

    validateContentHeight(yCurrent + 200);

    doc.text(
      "Badan Eksekutif Mahasiswa",
      pageWidth / 2,
      yCurrent,
      null,
      null,
      "center"
    );

    yCurrent += 15;

    doc.text(
      "Ikatan Keluarga Mahasiswa",
      pageWidth / 2,
      yCurrent,
      null,
      null,
      "center"
    );

    yCurrent += 15;

    doc.text(
      "Fakultas Kedokteran Universitas Indonesia",
      pageWidth / 2,
      yCurrent,
      null,
      null,
      "center"
    );

    yCurrent += 20;

    doc.addImage(ttdKetuaBEM, "jpg", 50 + Math.ceil(namaKetuaBEM.length / 2), yCurrent, 100, 50);

    yCurrent += 65;

    doc.text(
      namaKetuaBEM,
      namaKetuaBEM.length + 90,
      yCurrent,
      null,
      null,
      "center"
    );

    doc.text(
      target.nama_penanggung_jawab_delegasi ? target.nama_penanggung_jawab_delegasi : "Anonymous",
      pageWidth - 131,
      yCurrent,
      null,
      null,
      "center"
    );

    yCurrent += 17;

    doc.setFontSize(10);
    doc.setTextColor(80, 80, 80);

    doc.text(
      "KETUA",
      namaKetuaBEM.length + 90,
      yCurrent,
      null,
      null,
      "center"
    );

    doc.text(
      "PENANGGUNG JAWAB DELEGASI",
      pageWidth - 50,
      yCurrent,
      null,
      null,
      "right"
    );

    yCurrent += 44;

    doc.text(
      "",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    doc.save(`Proposal Reimburse.pdf`);
  };

  // @Table Section
  // Reimburse

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  const [dataReimburse, setDataReimburse] = useState([]);

  const dataFieldsReimburse = [
    {
      primary: true,
      checkbox: true,
      value: "id_reimburse",
      type: "text",
      label: "ID Reimburse",
      size: "default",
      position: "left",
      status: 0
    },
    {
      primary: false,
      value: "proposal",
      type: "file",
      action: "download",
      fileType: "pdf",
      downloadFile: generateProposalReimburse,
      label: "Proposal",
      size: "s",
      position: "left",
      status: 1
    },
    {
      primary: false,
      value: "tanggal_pengajuan",
      type: "text",
      label: "Tanggal Pengajuan",
      size: "default",
      position: "left",
      status: 1
    },
    {
      primary: false,
      value: "nama_lomba",
      type: "text",
      label: "Nama Lomba",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "cabang_lomba",
      type: "text",
      label: "Cabang Lomba",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "penyelenggara_lomba",
      type: "text",
      label: "Penyelenggara Lomba",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "deskripsi_lomba",
      type: "text",
      label: "Deskripsi Lomba",
      size: "xl",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "lokasi_lomba",
      type: "text",
      label: "Lokasi Lomba",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "awal_pelaksanaan_lomba",
      type: "text",
      label: "Awal Pelaksanaan Lomba",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "akhir_pelaksanaan_lomba",
      type: "text",
      label: "Akhir Pelaksanaan Lomba",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "tautan_resmi_lomba",
      type: "textlink",
      label: "Tautan Resmi Lomba",
      size: "m",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "penanggung_jawab_seleksi",
      type: "text",
      label: "Penanggung Jawab Seleksi",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "fasilitas_seleksi",
      type: "text",
      label: "Fasilitas Seleksi",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "total_dana_seleksi",
      type: "text",
      label: "Total Dana Seleksi",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "total_dana_lomba",
      type: "text",
      label: "Total Dana Lomba",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "nama_pembimbing",
      type: "text",
      label: "Nama Pembimbing",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "institusi_pembimbing",
      type: "text",
      label: "Institusi Pembimbing",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "kontak_pembimbing",
      type: "text",
      label: "Kontak Pembimbing",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "bank_tujuan",
      type: "text",
      label: "Bank Tujuan",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "no_rekening_tujuan",
      type: "text",
      label: "No. Rekening Tujuan",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "nama_pemilik_rekening_tujuan",
      type: "text",
      label: "Nama Pemilik Rekening Tujuan",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "nama_penanggung_jawab_delegasi",
      type: "text",
      label: "Nama Penanggung Jawab Delegasi",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "no_hp_penanggung_jawab_delegasi",
      type: "text",
      label: "No. HP Penanggung Jawab Delegasi",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "email_penanggung_jawab_delegasi",
      type: "text",
      label: "Email Penanggung Jawab Delegasi",
      size: "default",
      position: "right",
      status: 1
    }
  ];

  const [sortItemReimburse, setSortItemReimburse] = useState({
    by: "tanggal_pengajuan",
    mode: "DESC",
  });

  const [searchInputReimburse, setSearchInputReimburse] = useState("");

  const optionsSearchFilterReimburse = [
    { value: "tanggal_pengajuan", label: "Tanggal Pengajuan" },
    { value: "nama_lomba", label: "Nama Lomba" },
    { value: "cabang_lomba", label: "Cabang Lomba" },
    { value: "penyelenggara_lomba", label: "Penyelenggara Lomba" },
    { value: "deskripsi_lomba", label: "Deskripsi Lomba" },
    { value: "lokasi_lomba", label: "Lokasi Lomba" },
    { value: "awal_pelaksanaan_lomba", label: "Awal Pelaksanaan Lomba" },
    { value: "akhir_pelaksanaan_lomba", label: "Akhir Pelaksanaan Lomba" },
    { value: "tautan_resmi_lomba", label: "Tautan Resmi Lomba" },
    { value: "penanggung_jawab_seleksi", label: "Penanggung Jawab Seleksi" },
    { value: "fasilitas_seleksi", label: "Fasilitas Seleksi" },
    { value: "nama_pembimbing", label: "Nama Pembimbing" },
    { value: "institusi_pembimbing", label: "Institusi Pembimbing" },
    { value: "kontak_pembimbing", label: "Kontak Pembimbing" },
    { value: "bank_tujuan", label: "Bank Tujuan" },
    { value: "no_rekening_tujuan", label: "No. Rekening Tujuan" },
    { value: "nama_pemilik_rekening_tujuan", label: "Nama Pemilik Rekening Tujuan" },
    { value: "nama_penanggung_jawab_delegasi", label: "Nama Penanggung Jawab Delegasi" },
    { value: "no_hp_penanggung_jawab_delegasi", label: "No. HP Penanggung Jawab Delegasi" },
    { value: "email_penanggung_jawab_delegasi", label: "Email Penanggung Jawab Delegasi" }
  ];

  const [searchFilterReimburse, setSearchFilterReimburse] = useState([
    optionsSearchFilterReimburse[0]
  ]);

  // @Pagination Section
  // Reimburse

  const optionsNumberPerPageReimburse = [10, 20, 30, 40, 50];

  const [numberPerPageReimburse, setNumberPerPageReimburse] = useState(
    optionsNumberPerPageReimburse[0]
  );

  const [
    numberPerPageFromReimburse,
    setNumberPerPageFromReimburse,
  ] = useState(0);

  const [numberPerPageToReimburse, setNumberPerPageToReimburse] = useState(
    0
  );

  const [totalDataReimburse, setTotalDataReimburse] = useState(0);

  const [totalPageReimburse, setTotalPageReimburse] = useState(0);

  const [currentPageReimburse, setCurrentPageReimburse] = useState(0); 

  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        setIsDeleteLoading(true);

        const _delete = await deleteReimburse(dataSelected);

        setIsDeleteLoading(false);

        if(_delete.success) {
          setDataSelected([]);
          handleLoadData();
        } else {
          swal("Oops!", _delete.message ? _delete.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  const handleLoadData = async () => {
    const [reimburse, totalReimburse] = await Promise.all([
      getReimburse({
        order: sortItemReimburse,
        limit: numberPerPageReimburse,
        offset: 0
      }),
      getTotalReimburse({})
    ]);

    if(reimburse.success && totalReimburse.success) {
      // Set Reimburse
      setDataReimburse(reimburse.data);
      setTotalDataReimburse(totalReimburse.data.total);
      setTotalPageReimburse(
        Math.ceil(totalReimburse.data.total / numberPerPageReimburse)
      );
      setCurrentPageReimburse(totalReimburse.data.total > 0 ? 1 : 0);
      setNumberPerPageFromReimburse(
        totalReimburse.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToReimburse(
        totalReimburse.data.total > 0 ? reimburse.data.length : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper reimburse-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Reimburse</h1>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "67px",
                left: "initial",
                height: "calc(100% - 67px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      <Search
                        setData={setDataReimburse}
                        getData={getReimburse}
                        dataFields={dataFieldsReimburse}
                        sortItem={sortItemReimburse}
                        searchInput={searchInputReimburse}
                        setSearchInput={setSearchInputReimburse}
                        optionsSearchFilterBy={optionsSearchFilterReimburse}
                        searchFilterBy={searchFilterReimburse}
                        setSearchFilterBy={setSearchFilterReimburse}
                        numberPerPage={numberPerPageReimburse}
                        setNumberPerPageFrom={setNumberPerPageFromReimburse}
                        setNumberPerPageTo={setNumberPerPageToReimburse}
                        getTotalData={getTotalReimburse}
                        setTotalData={setTotalDataReimburse}
                        setTotalPage={setTotalPageReimburse}
                        setCurrentPage={setCurrentPageReimburse}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      <Pagination
                        setData={setDataReimburse}
                        getData={getReimburse}
                        dataFields={dataFieldsReimburse}
                        sortItem={sortItemReimburse}
                        searchInput={searchInputReimburse}
                        searchFilterBy={searchFilterReimburse}
                        optionsNumberPerPage={optionsNumberPerPageReimburse}
                        numberPerPage={numberPerPageReimburse}
                        setNumberPerPage={setNumberPerPageReimburse}
                        numberPerPageFrom={numberPerPageFromReimburse}
                        setNumberPerPageFrom={setNumberPerPageFromReimburse}
                        numberPerPageTo={numberPerPageToReimburse}
                        setNumberPerPageTo={setNumberPerPageToReimburse}
                        totalData={totalDataReimburse}
                        getTotalData={getTotalReimburse}
                        setTotalData={setTotalDataReimburse}
                        totalPage={totalPageReimburse}
                        setTotalPage={setTotalPageReimburse}
                        currentPage={currentPageReimburse}
                        setCurrentPage={setCurrentPageReimburse}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                <Table
                  ref={tableWrapperRef}
                  data={dataReimburse}
                  setData={setDataReimburse}
                  getData={getReimburse}
                  dataFields={dataFieldsReimburse}
                  sortItem={sortItemReimburse}
                  setSortItem={setSortItemReimburse}
                  searchInput={searchInputReimburse}
                  searchFilterBy={searchFilterReimburse}
                  numberPerPage={numberPerPageReimburse}
                  setNumberPerPageFrom={setNumberPerPageFromReimburse}
                  setNumberPerPageTo={setNumberPerPageToReimburse}
                  totalData={totalDataReimburse}
                  getTotalData={getTotalReimburse}
                  setTotalData={setTotalDataReimburse}
                  setTotalPage={setTotalPageReimburse}
                  setCurrentPage={setCurrentPageReimburse}
                  isDataTableLoading={isDataTableLoading}
                  setIsDataTableLoading={setIsDataTableLoading}
                  dataSelected={dataSelected}
                  setDataSelected={setDataSelected}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      )}
    </React.Fragment>
  )
}

export default Reimburse;
